<template>
  <div>
    <v-row class="ma-10">
      <v-row>
        <v-col cols="12">
          <material-card class="my-2" color="primary darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات المدارس العامه
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content v-for="item in schoolsColumns" :key="item">
                <div class="grey--text mb-4">
                  {{ $t("dashboard.schools." + item) }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ schools[item] || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="info darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الطلبه العامه
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.studentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics.students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.subscribedStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics.phone_subscribed_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.paidStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics.paid_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="info darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الكادر العامه
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.teachersCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics.teachers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.driversCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics.drivers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="green darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الطلبه للمدارس الحكومية
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.studentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_public.students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.subscribedStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_public.phone_subscribed_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.paidStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_public.paid_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="green darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الكادر للمدارس الحكومية
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.teachersCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_public.teachers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.driversCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_public.drivers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="orange darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الطلبه للمدارس الاهليه
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.studentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_private.students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.subscribedStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_private.phone_subscribed_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.paidStudentsCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_private.paid_students_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
        <v-col cols="6" xs="4" md="4" sm="12">
          <material-card class="my-2" color="orange darken-2" :loading="loading">
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  احصائيات الكادر للمدارس الاهليه
                </div>
              </div>
            </template>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.teachersCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_private.teachers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <div class="grey--text mb-4">
                  {{ $t("dashboard.driversCount") }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ statistics_private.drivers_count || 0 }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </material-card>
        </v-col>
      </v-row>
    </v-row>
    <material-card :loading="loading" class="ma-10" color="teal darken-1" full-header>
      <template #heading>
        <div class="white--text">
          <div class="font-weight-light">
            {{ $t("dashboard.governoratesStatistics") }}
          </div>
        </div>
      </template>
      <v-row>
        <v-col
          v-for="gov in governorates"
          :key="gov.id"
          cols="12"
          md="6"
          xl="4"
        >
          <v-card elevation="3" max-width="650">
            <v-card-title primary-title>
              <div class="text--primary">{{ gov.Governorate }}</div>
            </v-card-title>
            <v-card-text>
              <v-row dense no-gutters>
<!--                <v-col cols="12" md="12" class="mt-2 pe-1">-->
<!--                 <v-chip outlined label style="width: 100%">-->
<!--                    {{ $t("dashboard.supervisorsCount") + ": " }}-->
<!--                    {{ gov.SupervisorsCount }}-->
<!--                  </v-chip>-->
<!--                </v-col>-->
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                  <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.schoolsCount") + ": " }}
                    {{ gov.SchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.privateSchoolsCount") + ": " }}
                    {{ gov.PrivateSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.publicSchoolsCount") + ": " }}
                    {{ gov.PublicSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.freeSchoolsCount") + ": " }}
                    {{ gov.FreeSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">{{ $t("dashboard.paidSchoolsCount") + ": " }}
                    {{ gov.NotFreeSchoolsCount }}
                  </v-chip>
                </v-col>

                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">{{ $t("dashboard.activeSchoolsCount") + ": " }}
                    {{ gov.ActiveSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">{{ $t("dashboard.disabledSchoolsCount") + ": " }}
                    {{ gov.DisabledSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">{{ $t("dashboard.inactiveSchoolsCount") + ": " }}
                    {{ gov.InActiveSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12"  class="mt-2 pe-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.studentsCount") + ": " }}
                    {{ gov.statistics.students_count || 0 }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.subscribedStudentsCount") + ": " }}
                    {{ gov.statistics.phone_subscribed_students_count || 0 }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.paidStudentsCount") + ": " }}
                    {{ gov.statistics.paid_students_count || 0 }}
                  </v-chip>
                </v-col>
                <v-col cols="12"  class="mt-2 pe-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.teachersCount") + ": " }}
                    {{ gov.statistics.teachers_count || 0 }}
                  </v-chip>
                </v-col>
                <v-col cols="12"  class="mt-2 pe-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.driversCount") + ": " }}
                    {{ gov.statistics.drivers_count || 0 }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </material-card>
    <material-card :loading="loading" class="ma-10" color="primary darken-1" full-header>
      <template #heading>
        <div class="white--text">
          <div class="font-weight-light">
            {{ $t("dashboard.SchoolsGroupsStatistics") }}
          </div>
        </div>
      </template>
      <v-row>
        <v-col
          v-for="gov in schoolsGroups"
          :key="gov.id"
          cols="12"
          xl="4"
          md="6"
        >
          <v-card elevation="3" >
            <v-card-title primary-title>
              <div class="text--primary">{{ gov.SchoolGroup }}</div>
            </v-card-title>
            <v-card-text>
              <v-row  no-gutters>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.schoolsCount") + ": " }}{{ gov.SchoolsCount }}
                  </v-chip>
                </v-col>

                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">{{ $t("dashboard.privateSchoolsCount") + ": " }}
                    {{ gov.PrivateSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.publicSchoolsCount") + ": " }}
                    {{ gov.PublicSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.freeSchoolsCount") + ": " }}
                    {{ gov.FreeSchoolsCount }}
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="mt-2 pe-1">
                 <v-chip outlined label style="width: 100%">
                    {{ $t("dashboard.paidSchoolsCount") + ": " }}
                    {{ gov.NotFreeSchoolsCount || '0' }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </material-card>
  </div>
</template>
<script>
import MaterialCard from "../components/base/MaterialCard.vue";

export default {
  components: {
    MaterialCard,
  },
  data() {
    return {
      loading: true,
      schools: {
        all: 0,
        private: 0,
        gov: 0,
        free: 0,
        paid: 0,
        active: 0,
        disabled: 0,
        deactivate: 0,
        supervisor: 0,
        test: 0,
      },
      governorates: [],
      statistics: {},
      statistics_public: {},
      statistics_private: {},
      schoolsGroups: [],
    };
  },
  computed: {
    schoolsColumns() {
      return Object.keys(this.schools);
    },
    series() {
      var list = [
        {
          name: "  " + this.$t("count") + "  ",
          data: this.mostActiveProducts.map(function (item) {
            return item["count"];
          }),
        },
      ];
      return list;
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: this.mostActiveProducts.map(function (item) {
            return item["productName"];
          }),
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },

    labelsActivationByCategory() {
      var transLabel = [];
      var labels = this.activationByCategory.map(function (item) {
        return item["category"];
      });
      for (let index = 0; index < labels.length; index++) {
        transLabel.push(this.$t(labels[index]));
      }
      return transLabel;
    },

    seriesActivationByCategory() {
      var data = this.activationByCategory.map(function (item) {
        return item["count"];
      });
      return data;
    },
    optionsActivationByCategory() {
      return {
        chart: {
          type: "donut",
          height: 360,
        },
        labels: this.labelsActivationByCategory,
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      };
    },
  },
  created() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      this.$axios
          .get("GetGovernoratesStatistics", {noToast: true})
          .then((response) => {
            this.governorates = response.data.data.governorates;
            this.statistics = response.data.data.statistics;
            this.statistics_public = response.data.data.statistics_public;
            this.statistics_private = response.data.data.statistics_private;
            this.schoolsGroups = response.data.data.schools_groups;
            this.loading = false;
            this.governorates.forEach(governorate => {
              this.schools.all += governorate.SchoolsCount;
              this.schools.private += governorate.PrivateSchoolsCount;
              this.schools.gov += governorate.PublicSchoolsCount;
              this.schools.free += governorate.FreeSchoolsCount;
              this.schools.paid += governorate.NotFreeSchoolsCount;
              this.schools.supervisor += governorate.SupervisorsCount;
              this.schools.active += governorate.ActiveSchoolsCount;
              this.schools.disabled += governorate.DisabledSchoolsCount;
              this.schools.deactivate += governorate.InActiveSchoolsCount;
              this.schools.test += governorate.TestSchools;
            })
          })
    },
    edit(item) {
      this.editedItem.productGuid = item.guid;
      this.dialog = true;

      this.$axios
          .get("Products/GetFeatures?productGuid=" + item.guid)
          .then(() => {
          });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    save() {
      this.$axios
          .post("CustomersProducts/Add", this.editedItem)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.close();
            }
          });
    },
  },
};
</script>
